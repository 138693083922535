import axios from "axios";
const token = btoa(
  `${process.env.REACT_APP_DOCTORLIVROS_USER}:${process.env.REACT_APP_DOCTORLIVROS_USER_TOKEN}`
);

const instance = axios.create({
  baseURL: `https://www.doctorlivros.com.br/api/v1.0/`,
  // baseURL: `http://192.168.0.187:8010/api/v1.0/`,
  headers: {
    Authorization: `basic ${token}`,
    "Content-Type": `application/json`,
  },
});

axios.defaults.preflightContinue = true;

export const getProduct = (id) => {
  return instance.get(`pay/products?query=${id}`);
};

export const getOrder = (id) => {
  return instance.get(`orders/${id}`);
};

export const couponValidate = (coupon) => {
  return instance.get(`cupom/validate?cupom=${coupon}`);
};

export const savePerson = (data) => {
  return instance.post(`pay/users`, data);
};

export const setCartInstance = (data) => {
  return instance.post(`pay/cart`, data);
};

export const placeOrder = (data) => {
  return instance.post(`pay/order`, data);
};

export const transactions = (data) => {
  return instance.post(`pay/transactions`, data);
};

export const notifyOrder = (id) => {
  return instance.get(`pay/order/notify/${id}`);
};

export const getPix = (data) => {
  return instance.post(`pay/pix`, data);
};

export const searchProduct = (query) => {
  return instance.get(`search/products?query=${query}`);
};

export const getSellers = () => {
  return instance.get(`pay/sellers`);
};

export const getShippingOptions = (data) => {
  const payload = {
    from: {
      postal_code: data.addr_postalcode.replace("-", ""),
    },
    to: {
      postal_code: "03511000",
    },
    options: {
      receipt: false,
      own_hand: false,
    },
    services: "",
    products: data.cart.map((item) => ({
      id: item.id,
      width: item.width,
      height: item.height,
      length: item.length,
      weight: item.weight,
      insurance_value: item.price,
      quantity: 1,
    })),
  };

  return instance.post(`pay/melhorenvio`, payload);
};
