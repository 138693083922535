import React from 'react';
import UserProvider from './providers/UserProvider';
import ThemesProvider from './providers/ThemeProvider'
import { Provider } from 'react-redux'
import Routes from './routes/index';

import storeConfig from './store/storeConfig';

export default function App(){

    return (
        <Provider store={storeConfig()}>
            <UserProvider>
                <ThemesProvider>
                    <Routes />
                </ThemesProvider>
            </UserProvider>
        </Provider>
    )
    
}