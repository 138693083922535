import React from 'react'
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom'

import Default from "../layouts/Default"
import Checkout from '../views/Checkout'
import Order from '../views/Order'
import LinkGenerator from '../views/LinkGenerator'

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Default />}>
                    <Route index element={<Checkout />} />
                    <Route path="confirmacao/:id" element={<Order />} />
                    <Route path="link" element={<LinkGenerator />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes