import * as React from 'react';
import { Container, Box } from '@mui/material'

import { styled, alpha } from '@mui/material/styles';

export const CheckoutWrapper = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '740px !important',
    margin: '0 auto',
    minHeight: '100vh',
    padding: '30px 0'
}));

export const ContentBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius:'1em',
    padding:'1em',
    [theme.breakpoints.up('sm')]: {
        padding:'2em',
    },
}));

export const Logo = styled('img')(({ theme }) => ({
    marginBottom: '40px',
    width: '230px',
}));