import React, { Component, useEffect } from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles'
import { connect } from 'react-redux'
import { mapStateToProps, setMode } from '../store/functions/theme'

//Create pallete on https://material.io/resources/color/

const getTheme = (mode) => {

    return createTheme({
        palette: {
            mode,
            primary: {
                main: "#cd0f1b",
                light: "#ff5344",
                dark: "#940000",
            },
            secondary: {
                main: "#353745",
                light: "#5f6170",
                dark: "#0f111e",
            },
            tertiary: {
                main: "#2d0007",
                light: "#582930",
                dark: "#110000",
            },
        }
    })

}

const ThemesProvider = (props) => {

    return (
        <ThemeProvider theme={getTheme(props.mode)}>
            {props.children}
        </ThemeProvider>
    )

}

export default connect(
    mapStateToProps,
    setMode
)(ThemesProvider)