import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {
  Snackbar,
  Alert,
  CircularProgress,
  Button,
  TextField,
  Typography,
  Grid,
  Divider,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  } from '@mui/material'
import InputMask from 'react-input-mask'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiscountIcon from '@mui/icons-material/Discount';

import FormMainFields from './formMainFields'
import PaymentForm from './paymentForm'

import { getShippingOptions } from '../../api'

function validateCPF(cpf) {	
  let rev,
      add
	cpf = cpf.replace(/[^\d]+/g,'');	
	if(cpf == '') return false;	
	// Elimina CPFs invalidos conhecidos	
	if (cpf.length != 11 || 
		cpf == "00000000000" || 
		cpf == "11111111111" || 
		cpf == "22222222222" || 
		cpf == "33333333333" || 
		cpf == "44444444444" || 
		cpf == "55555555555" || 
		cpf == "66666666666" || 
		cpf == "77777777777" || 
		cpf == "88888888888" || 
		cpf == "99999999999")
			return false;		
	// Valida 1o digito	
	add = 0;	
	for (let i=0; i < 9; i ++)		
		add += parseInt(cpf.charAt(i)) * (10 - i);	
		rev = 11 - (add % 11);	
		if (rev == 10 || rev == 11)		
			rev = 0;	
		if (rev != parseInt(cpf.charAt(9)))		
			return false;		
	// Valida 2o digito	
	add = 0;	
	for (let i = 0; i < 10; i ++)		
		add += parseInt(cpf.charAt(i)) * (11 - i);	
	rev = 11 - (add % 11);	
	if (rev == 10 || rev == 11)	
		rev = 0;	
	if (rev != parseInt(cpf.charAt(10)))
		return false;		
	return true;   
}

const validate = values => {
  const errors = {}
  const requiredFields = [
    'fullname',
    'email',
    'confirmedEmail',
    'document',
    'phone',
    'addr_postalcode',
    'addr_line1',
    'addr_number',
    'addr_district',
    'addr_city',
    'addr_state',
  ]
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Campo obrigatório'
    }
  })
  if(values.document) {
    if(!validateCPF(values.document)) errors.document = 'O CPF informado é inválido!'
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Endereço de e-mail inválido!'
  }
  if (
    values.confirmedEmail &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.confirmedEmail)
  ) {
    errors.confirmedEmail = 'Endereço de e-mail inválido!'
  }else{
    if (
      values.email != values.confirmedEmail
    ) {
      errors.confirmedEmail = 'A confirmação é diferente do e-mail informado!'
    }  
  }
  
  return errors
}

// const requiredValidator = value => paymentMethod === "creditcard" ? (value ? undefined : 'Campo obrigatório') : undefined

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )

const renderTextFieldWithMask = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <InputMask
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )

const renderSelectField = ({
    label,
    input,
    meta: { touched, invalid, error },
    children,
    ...custom
  }) => (
    <TextField
      label={label}
      select
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    >
        {children}
    </TextField>
  )

let Form = (props) => {
  const { 
    initialValues,
    paymentMethod,
    setPaymentMethod,
    setShippingOptions,
    shippingOptions,
    setShippingResponse,
    handleSubmit, 
    pristine, 
    change,
    submitting,
    cart,
    loading, 
    addr_postalcode,
    coupon,
    total,
    inCashDiscountAmount,
    discountAmount,
    credicardInfo,
    submitFailed,
    placeOrderLoading,
    sellerDiscount,
    freeShipping,
    shipping,
    submitError } = props
  const [CEPloading, setCEPloading] = useState(false)
  const [snackbar, setSnackbar] = useState(false)

  const handleShipping = () => {

    getShippingOptions({
      cart,
      addr_postalcode,
    })
    .then(options => {
      setShippingOptions(options.data.filter(item => {
        return (item.error===undefined&&(item.company.id===1||item.company.id===2))
      }))
    })
    .catch(err => {
      console.error(err)
    })

  }

  const handleShippingResponse = (id) => {
    if (!id) return;
    const options = shippingOptions.filter(item => item.id === id);
    setShippingResponse(options[0])
  }

  const handleShippingChange = (e,newValue) => {
    const option = newValue.split(",")
    shipping.set(Number(option[1]))
    handleShippingResponse(Number(option[3]));
  }

  useEffect(() => {

    if(submitFailed){
      submitError.set(true)
      submitError.setHelperText('Confira os campos obrigatórios antes de concluir!')
    }

  }, [submitFailed])

  useEffect(() => {
    if(!addr_postalcode) return

    const code = addr_postalcode.replace(/\D/g, '')
    if(code.length == 8){
      handleShipping()

      setCEPloading(true)
      axios.get(`https://viacep.com.br/ws/${code}/json/`)
      .then(response => {

        if(!response.data.erro){
          const address = response.data

          change('addr_line1',address.logradouro)
          change('addr_city',address.localidade)
          change('addr_state',address.uf)
          change('addr_district',address.bairro)

        }

        setCEPloading(false)
      })
    }
  },[addr_postalcode])

  return (
    <form onSubmit={handleSubmit}>
      <FormMainFields
        CEPloading={CEPloading}
        Field={Field}
        renderTextField={renderTextField}
        renderTextFieldWithMask={renderTextFieldWithMask}
        renderSelectField={renderSelectField}
      />

      <Divider sx={{margin:'1em 0'}} />

      <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <DiscountIcon sx={{mr:1}} />
            <Typography>Tem um cupom de desconto?</Typography>
          </AccordionSummary>
          <AccordionDetails>
          {coupon.data && <Grid container alignItems="center" sx={{backgroundColor: '#f9f9f9'}}>
              <Grid item xs sx={{pl:1}}>
                <Typography variant="body2" component="div">Cupom adicionado: <b>{coupon.data.cupom} ({coupon.data.discount}% OFF)</b></Typography>
              </Grid>
              <Grid item xs="auto">
                <Button onClick={() => coupon.remove() } variant="contained">Remover</Button>
              </Grid>
            </Grid>}
            {!coupon.data && <Grid container alignItems="center">
              <Grid item xs>
                <TextField
                  label="Digite o código"
                  variant="outlined"
                  name="cupom"
                  onChange={e=>{ coupon.set(e.target.value) }}
                  fullWidth={true}
                  margin="dense"
                  size="small"
                  color="secondary"
                />
              </Grid>
              <Grid item xs="auto">
                <Button onClick={() => coupon.handler() }>
                  {coupon.loading && <CircularProgress size={24} />}
                  {!coupon.loading && 'Aplicar'}
                </Button>
              </Grid>
            </Grid>}
          </AccordionDetails>
      </Accordion>

      <PaymentForm
        Field={Field}
        maxInstallments={initialValues.installments}
        credicardInfo={credicardInfo}
        renderTextField={renderTextField}
        renderSelectField={renderSelectField}
        renderTextFieldWithMask={renderTextFieldWithMask}
        setPaymentMethod={setPaymentMethod}
        total={total}
      />

      {freeShipping===0 && <div>
        <Divider sx={{mb:1,mt:3}}>Selecione o Frete</Divider>
        <Field
        label="Selecione o método de envio"
        variant="outlined"
        select
        name="shipping"
        fullWidth={true}
        margin="dense"
        size="small"
        component={renderSelectField}
        value=''
        onChange={handleShippingChange}
        >
        {shippingOptions.map((option) => (
          <MenuItem key={option.id} value={`${option.company.name} - ${option.name},${option.price},${option.delivery_time},${option.id}`}>
            {`${option.company.name} - ${option.name} - ${Number(option.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - ${option.delivery_time} dia${option.delivery_time>1 ? "s" : ""} út${option.delivery_time>1 ? "eis" : "il"} `}
          </MenuItem>
        ))}
        </Field>
      </div>}

      <Divider sx={{mb:1,mt:3}}>Detalhes da Compra</Divider>

      {cart.map(product => (
        <Box key={`cart_${product.id}`} sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#EFEFEF' }}>
          <Typography variant='button' sx={{fontWeight:'bold',p:1}}>
            {product.name}
          </Typography>
          <Typography variant='body2' sx={{ flex: '1 0 auto',p:1,textAlign:"right" }}>
            {product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Typography>
        </Box>
      ))}

      {coupon.data && <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f9f9f9' }}>
        <Typography variant='button' sx={{fontWeight:'bold',p:1}}>
        CUPOM: {coupon.data.cupom} ({coupon.data.discount}% OFF)
        </Typography>
        <Typography variant='body2' sx={{ flex: '1 0 auto',p:1,textAlign:"right",color:'primary.main' }}>
        - {discountAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </Box>}
      {sellerDiscount>0 && <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f9f9f9' }}>
        <Typography variant='button' sx={{fontWeight:'bold',p:1}}>
        DESCONTO
        </Typography>
        <Typography variant='body2' sx={{ flex: '1 0 auto',p:1,textAlign:"right",color:'primary.main' }}>
        - {sellerDiscount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </Box>}
      {inCashDiscountAmount>0 && <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f9f9f9' }}>
        <Typography variant='button' sx={{fontWeight:'bold',p:1}}>
        DESC. PAGAMENTO À VISTA (5% OFF)
        </Typography>
        <Typography variant='body2' sx={{ flex: '1 0 auto',p:1,textAlign:"right",color:'primary.main' }}>
        - {inCashDiscountAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </Box>}
      
      {shipping.get>0 && <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f9f9f9' }}>
        <Typography variant='button' sx={{fontWeight:'bold',p:1}}>
        Frete
        </Typography>
        <Typography variant='body2' sx={{ flex: '1 0 auto',p:1,textAlign:"right" }}>
        {shipping.get.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </Box>}
      
      {freeShipping===1 && <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f9f9f9' }}>
        <Typography variant='button' sx={{fontWeight:'bold',p:1}}>
        FRETE GRÁTIS
        </Typography>
        <Typography variant='body2' sx={{ flex: '1 0 auto',p:1,textAlign:"right" }}>
          R$ 0,00
        </Typography>
      </Box>}

      <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f9f9f9' }}>
        <Typography variant='button' sx={{fontWeight:'bold',p:1,color:'primary.main'}}>
        TOTAL
        </Typography>
        <Typography variant='button' sx={{ flex: '1 0 auto',p:1,textAlign:"right",color:'primary.main' }}>
        {total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        open={submitError.error}
        autoHideDuration={6000}
        onClose={() => submitError.set(false)}
      >
        <Alert onClose={() => submitError.set(false)} severity="error">
        {submitError.helperText}
        </Alert>
      </Snackbar>

      <Button
        fullWidth={true}
        color="success"
        variant="contained"
        size="large"
        sx={{mt:2,mb:2}}
        type="submit"
        disabled={pristine || submitting}
      >
        {placeOrderLoading && <CircularProgress sx={{color:'white'}} size={24} />}
        {!placeOrderLoading && 'Comprar agora'}
      </Button>
    </form>
  )

}

Form =  reduxForm({
  form: 'MaterialUiForm', // a unique identifier for this form
  validate,
})(Form)

const selector = formValueSelector('MaterialUiForm')

Form = connect(state => {
    const { cc_number, cc_name, cc_expiryMonth, cc_expiryYear, cc_cvc, addr_postalcode} = selector(state, 'cc_number', 'cc_name', 'cc_expiryMonth', 'cc_expiryYear', 'cc_cvc', 'addr_postalcode')

    return {
        addr_postalcode,
        credicardInfo: { cc_number, cc_name, cc_expiryMonth, cc_expiryYear, cc_cvc}
    }
})(Form)

export default Form