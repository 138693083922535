import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import themeReducer from './reducers/theme'
import authReducer from './reducers/auth'
import { reducer as formReducer } from 'redux-form'

const reducers = combineReducers({
    theme: themeReducer,
    auth: authReducer,
    form: formReducer,
})

function storeConfig() {
    return createStore(reducers, applyMiddleware(thunkMiddleware))
}

export default storeConfig