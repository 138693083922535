import React, { useState } from "react"
import { Outlet, Link } from 'react-router-dom'
import {Container, CircularProgress, Button, Snackbar, TextField, Alert, Typography} from '@mui/material'

import { CheckoutWrapper, Logo, ContentBox } from './styles'

import logo from '../../assets/images/logo-light.svg'

function Default(props) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState(false)
  const [snackbartxt, setSnackbarTxt] = useState("")

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      signInWithEmailAndPasswordHandler()
    }
  }

  const signInWithEmailAndPasswordHandler = async () => {
    setSnackbar(false)

    if(!email){
      setSnackbarTxt("Preencha o e-mail para entrar.")
      setSnackbar(true)
      return false
    }

    if(!password){
      setSnackbarTxt("Informe sua senha para entrar.")
      setSnackbar(true)
      return false
    }

    if (!loading) {
      setLoading(true)

      alert("dashboard")
    }
    
  }
  
  return (

    <Container maxWidth={false} sx={{
      backgroundColor:'tertiary.main',
    }}>
    <CheckoutWrapper>
        <Logo src={logo} alt="Doctor Livros" />
        <ContentBox boxShadow={3}>
            <Outlet />
        </ContentBox>

        <Snackbar
            anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
            open={snackbar}
            autoHideDuration={6000}
            onClose={() => setSnackbar(false)}
        >
            <Alert onClose={() => setSnackbar(false)} severity="warning">
            {snackbartxt}
            </Alert>
        </Snackbar>
      
    </CheckoutWrapper>
    </Container>
  )
  
}

export default Default