import React, { useEffect, useRef, useState } from "react"
import CopyToClipboard from 'react-copy-to-clipboard'
import MediaControlCard from "../../components/MediaControlCard"
import { useSearchParams } from "react-router-dom"
import InputMask from 'react-input-mask'
import MoneyInput from '@rschpdr/react-money-input'
import { searchProduct, getSellers } from "../../api"
import { useTheme } from "@emotion/react"

import { 
Grid,
TextField,
Alert,
Divider,
Box,
LinearProgress,
CircularProgress,
IconButton,
List,
ListItem,
ListItemText,
ListItemAvatar,
Avatar,
Button,
MenuItem,
FormControlLabel,
FormGroup,
Switch,
InputAdornment,
} from "@mui/material"

import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';

const LinkGenerator = () => {
    const theme = useTheme()
    const [searchParams, setSearchParams] = useSearchParams()
    const seller = searchParams.get("seller") || 0
    const admin = searchParams.get("admin") ? false : true
    const [loading,setLoading] = useState(false)
    const [loadingSearch,setLoadingSearch] = useState(false)
    const [cart,setCart] = useState([])
    const [results,setResults] = useState([])
    const [sellers,setSellers] = useState([])
    const textSearch = useRef("")
    const [searched,setSearched] = useState(false)
    const [copied, setCopied] = useState(false)
    const [discountAmount, setDiscountAmount] = useState("")
    const [freeShipping, setFreeShipping] = useState(false)
    const uri = "https://pay.doctorlivros.com.br?id="
    const [link,setLink] = useState(uri)
    const [fields,setFields] = useState({
        name: "",
        email: "",
        phone: "",
        seller,
        maxInstallments: "6",
    })
  
    const handleDiscountAmountChange = (e) => {
      setDiscountAmount(e.target.value);
    }
  
    const handleFreeShippingChange = (e) => {
      setFreeShipping(e.target.checked);
    }

    const handleLink = () => {
        const ids = cart.map(product => (product.idproduct))
        let params = []

        if(fields.seller){
          params.push(`seller=${fields.seller}`)
        }

        if(fields.name){
          params.push(`name=${fields.name.replace(" ","+")}`)
        }

        if(fields.phone){
          params.push(`phone=${fields.phone.replace(" ","+")}`)
        }
        
        if(fields.maxInstallments){
          params.push(`installments=${fields.maxInstallments}`)
        }

        if(discountAmount){
          params.push(`amount=${btoa(parseFloat(discountAmount.replace(',', '.')))}`)
        }

        if(freeShipping){
          params.push(`freeShipping=1`)
        }

        if(fields.email){
          params.push(`email=${fields.email}`)
        }

        setLink(encodeURI(`${uri}${ids.join()}${params.length ? '&' : ''}${params.join('&')}`))
    }

    useEffect(() => {
      getSellers()
      .then(response => {
        console.log(response.data.data)
        setSellers(response.data.data)
      })
      .catch(err => {
        console.error(err)
      })
    },[])

    useEffect(() => {

        handleLink()
        
    },[fields,cart,discountAmount,freeShipping])

    const handleSearchInput = (e) => {
        setResults([])
        setSearched(false)
    }

    const handleCartErase = () => {
        setCart([])
    }

    const handleKeyPress = (e) => {
        if(e.keyCode == 13){
            handleSearch(e)
         }
    }

    const handleSearch = async (e) => {

        setLoadingSearch(true)
        setResults([])
        
        if(textSearch.current.value){
            await searchProduct(textSearch.current.value)
            .then(response => {
    
                if(Number(response.data.products.total)){
                    setResults(response.data.products.data)
                }
                
            })
            .catch(err => {
                console.error(err)
            })

            setSearched(true)
        }

        setLoadingSearch(false)
            
    }

    const handleFieldsChange = (e) => {
        setFields({...fields, [e.target.name]:e.target.value})
    }

    const handleAddToCart = (product) => {
        const newCart = cart
        newCart.push(product)

        setCart(newCart)
        textSearch.current.value = ''
        setResults([])
        setSearched(false)
        handleLink()
    }

    const copy = () => {
      setCopied(true)

      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }

    const noSearch = () => {

        if (results.length == 0&&searched === true) {
            return <Alert severity="warning">Nenhum livro encontrado para a pesquisa.</Alert>
        }
            
    }

    const copyToClipboard = async () => {

      // await fetch('https://api-ssl.bitly.com/v4/shorten', {
      //     method: 'POST',
      //     headers: {
      //         Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
      //         'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //         domain: "doclivros.com",
      //         long_url: `${link}`,
      //     })
      // }).then(response => {
      //     if (response.status!==200&&response.status!==201) {
      //         return;
      //     } else {
      //         return response.json();
      //     }
      // }).then(data => {
      //     if (data) {
      //       console.log(data.link);
      //     }
      // })

      navigator.clipboard.writeText(link);
      copy();
      
    }

    if(!loading){
    return (
        <Box sx={{width:"600px",[theme.breakpoints.down('lg')]: {width: 'auto'},}}>
            {!cart.length && <Alert severity="info">Nenhum livro adicionado.</Alert>}
            {cart.map(product => (
                <MediaControlCard
                key={`media_${product.idproduct}`}
                image={`https://doctorlivros.com.br${product.image}`}
                title={product.product}
                subtitle={product.custom_fields["Autor(es)"] || ''}
                bottomline={Number(product.special>0 ? product.special : product.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                />
            ))}
            {cart.length>0 && <Button onClick={handleCartErase} fullWidth color="info">Limpar carrinho</Button>}
            <Divider sx={{my:1}} />
            <TextField
            id="filled-search"
            label="Código, nome ou referência"
            type="search"
            variant="filled"
            inputRef={textSearch}
            onChange={handleSearchInput}
            onKeyDown={handleKeyPress}
            fullWidth
            color="info"
            InputProps={{endAdornment: <Button onClick={handleSearch} variant="contained" color="info"><SearchIcon /></Button>}}
            />
            <List>
              {results.map(product => (
                <ListItem
                    key={product.idproduct}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => {handleAddToCart(product)}}>
                      <AddCircleIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <img src={`https://doctorlivros.com.br${product.image}`} width="50" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={product.product}
                    secondary={product.custom_fields["Autor(es)"] || ''}
                  />
                </ListItem>
              ))}
            </List>
            {loadingSearch && <LinearProgress color="info" sx={{my:1}} />}
            {noSearch()}
            <Divider sx={{my:1}} />
                <TextField
                label="Nome completo"
                variant="outlined"
                name="name"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                onChange={handleFieldsChange}
                />
                <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                    label="E-mail"
                    variant="outlined"
                    name="email"
                    fullWidth={true}
                    margin="dense"
                    size="small"
                    color="secondary"
                    onChange={handleFieldsChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputMask
                    mask="(99) 99999-9999"
                    disabled={false}
                    name="phone"
                    onChange={handleFieldsChange}
                    >
                    {(inputProps) => <TextField
                    {...inputProps}
                    label="Celular"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    size="small"
                    color="secondary"
                    />}
                    </InputMask>
                </Grid>
                </Grid>
            <Divider sx={{my:1}} />
              <TextField
              label="Selecione o vendedor"
              variant="outlined"
              select
              name="seller"
              fullWidth={true}
              margin="dense"
              size="small"
              disabled={admin}
              defaultValue={seller > 0 ? seller : ""}
              onChange={handleFieldsChange}
              >
              {sellers.map((option) => (
                <MenuItem key={option.iduser} value={option.iduser}>
                  {option.person}
                </MenuItem>
              ))}
              </TextField>
            <Divider sx={{my:1}} />
              <TextField
              label="Máximo de parcelas no cartão"
              variant="outlined"
              select
              name="maxInstallments"
              fullWidth={true}
              margin="dense"
              size="small"
              defaultValue="6"
              onChange={handleFieldsChange}
              >
              {[1,2,3,4,5,6,7,8,9,10].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}x
                </MenuItem>
              ))}
              </TextField>
            <Divider sx={{my:1}} />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                  {/* <MoneyInput
                    customInput={TextField}
                    currencyConfig={{
                      locale: "pt-BR",
                      currencyCode: "BRL",
                      currencyDisplay: "symbol"
                    }}
                    onChange={handleDiscountAmountChange}
                    name="discount"
                    label="Desconto"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    size="small"
                    color="secondary"
                    value={discountAmount}
                    /> */}
                    <TextField
                        onChange={handleDiscountAmountChange}
                        margin="dense"
                        size="small"
                        color="secondary"
                        rows={4}
                        value={discountAmount}
                        fullWidth
                        variant="outlined"
                        name="discount"
                        label="Desconto"
                    />
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel control={<Switch onChange={handleFreeShippingChange} color="success" />} label="Frete grátis" />
                </FormGroup>
              </Grid>
            </Grid>
            <Divider sx={{my:1}} />
            <TextField
                color="secondary"
                multiline
                rows={4}
                value={link}
                fullWidth
                sx={{mb:1}}
                disabled
            />
            <Button variant='contained' size='large' color='secondary' fullWidth onClick={copyToClipboard}>{copied ? 'Copiado com sucesso!' : 'Copiar código'}</Button>
        </Box>
    )
    }else{
        return (
          <Box sx={{display:'flex',flexDirection: 'column',alignItems:'center'}}>
            <CircularProgress color='success' />
          </Box>
        )
    }

}

export default LinkGenerator