import { AUTH_USER_FETCHED, AUTH_TOKEN_VALIDATED } from '../actions/actionsTypes'

const userKey = 'ad_user'
const INITIAL_STATE = {
    user: JSON.parse(localStorage.getItem(userKey)),
    validToken: false,
}

export default function authReducer(state = INITIAL_STATE, action) {

    switch(action.type) {
        case AUTH_TOKEN_VALIDATED:
            if(action.payload) {
                return {
                    ...state,
                    validToken: true
                }
            }else{
                localStorage.removeItem(userKey)
                return {
                    ...state,
                    user: null,
                    validToken: false,
                }
            }
        case AUTH_USER_FETCHED:
            localStorage.setItem(userKey, JSON.stringify(action.payload))
            return {
                ...state,
                user: action.payload,
                validToken: true,
            }
        default:
            return state
    }

}