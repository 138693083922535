import { THEME_MODE_CHANGED } from '../actions/actionsTypes'

export function mapStateToProps(state) {
    return {
        mode: state.theme.mode
    }
}

export function setMode(newMode) {
    return {
        type: THEME_MODE_CHANGED,
        payload: newMode,
    }
}