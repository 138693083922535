import React from 'react'
import {
  TextField,
  Typography,
  Grid,
  Box,
  CardContent,
  Card,
  MenuItem,
  } from '@mui/material'
import Cards from 'react-credit-cards'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab';

import 'react-credit-cards/es/styles-compiled.css'

import CreditCardIcon from '@mui/icons-material/CreditCard';
import PixIcon from '@mui/icons-material/Pix';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SecurityIcon from '@mui/icons-material/Security';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PercentIcon from '@mui/icons-material/Percent';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, border: '1px solid #EFEFEF', borderRadius:2, marginTop: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const validThruMonth = () => {
  let months = []

  for (let index = 1; index < 13; index++) {
    months.push(('0' + index).slice(-2))
  }

  return months
}

const validThruYear = () => {
  let year = []
  const date = new Date()

  for (let index = date.getFullYear(); index < date.getFullYear()+21; index++) {
    year.push((index))
  }

  return year
}

const PaymentForm = ({
    product,
    maxInstallments,
    Field,
    credicardInfo,
    renderTextField,
    renderSelectField,
    renderTextFieldWithMask,
    total,
    setPaymentMethod
    }) => {

    const [tab, setTab] = React.useState(0)
    const expiry = `${credicardInfo.cc_expiryMonth || ''}/${credicardInfo.cc_expiryYear || ''}`
    const [focus, setFocus] = React.useState('')
  
    const handleTabChange = (event, newTab) => {
      setTab(newTab)
    }
  
    const handleCreditCardFocus = (target) => {
        setFocus(target)
    }

    const getInstallments = () => {
        let maxInstallment = Number(maxInstallments)
        const installments = []

        if (maxInstallment > 10) {
          maxInstallment = 6;
        }
  
        for (let index = 1; index < maxInstallment+1; index++) {
          installments.push([index,(total/index).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })])
        }
  
        return installments
    }

    const getBilletInstallments = () => {
        const maxInstallment = 3
        const installments = []
  
        for (let index = 1; index < maxInstallment+1; index++) {
          installments.push([index,(total/index).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })])
        }
  
        return installments
    }

    const pix = () => (
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4}>
        <Card variant="outlined">
          <CardContent>
            <PercentIcon />
            <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
            Á vista com desconto de 5%
            </Typography>
            <Typography variant="body2" color="secondary">
            O pagamento via PIX é processado em segundos e você ganha 5% de desconto.
            </Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
        <Card variant="outlined">
          <CardContent>
            <QrCodeScannerIcon />
            <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
            Pague de forma simples e rápida
            </Typography>
            <Typography variant="body2" color="secondary">
            É só acessar a área PIX no aplicativo do seu banco e escanear o QR code ou digitar o código
            </Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
        <Card variant="outlined">
          <CardContent>
            <SecurityIcon />
            <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
            Transação segura
            </Typography>
            <Typography variant="body2" color="secondary">
            O PIX foi desenvolvido para facilitar suas compras, o QR Code e código serão apresentados após a finalização da compra.
            </Typography>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
    )
  
    const billet = () => (
      <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
        <Card variant="outlined">
          <CardContent>
            <PercentIcon />
            <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
            Pague no boleto em 3x sem juros
            </Typography>
            <Typography variant="body2" color="secondary">
            Pagamento para 30, 60 e 90 dias da data da compra.
            </Typography>
          </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
        <Card variant="outlined">
          <CardContent>
            <ShoppingCartCheckoutIcon />
            <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
            Receba primeiro, pague depois
            </Typography>
            <Typography variant="body2" color="secondary">
            Faça sua compra e receba seu livro antes mesmo de começar a pagá-lo
            </Typography>
          </CardContent>
        </Card>
        </Grid>
      </Grid>
      <Field
        label="Selecione o número de parcelas"
        variant="outlined"
        select
        name="billet_installments"
        fullWidth={true}
        margin="dense"
        size="small"
        component={renderSelectField}
        >
        {getBilletInstallments().map((option) => (
          <MenuItem key={option[0]} value={option[0]}>
            {`${option[0]} x de ${option[1]} sem juros`}
          </MenuItem>
        ))}
        </Field>
        </React.Fragment>
    )
  
    const creditCard = () => (
      <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} order={{xs:2,lg:1}}>
          <Field
            mask="9999 9999 9999 9999"
            name="cc_number"
            onFocus={e=>{ handleCreditCardFocus('number') }}
            component={renderTextFieldWithMask}
          >
            {(inputProps) => <TextField
                {...inputProps}
                label="Número do cartão"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                />}
          </Field>
          <Field
            label="Titular do cartão"
            variant="outlined"
            name="cc_name"
            onFocus={e=>{ handleCreditCardFocus('name') }}
            fullWidth={true}
            margin="dense"
            size="small"
            color="secondary"
            component={renderTextField}
          />
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field
                label="Mês"
                variant="outlined"
                select
                name="cc_expiryMonth"
                onFocus={e=>{ handleCreditCardFocus("expiry") }}
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                component={renderSelectField}
              >
              {validThruMonth().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Ano"
                variant="outlined"
                select
                name="cc_expiryYear"
                onFocus={e=>{ handleCreditCardFocus("expiry") }}
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                component={renderSelectField}
              >
              {validThruYear().map((option) => (
                <MenuItem key={option} value={option.toString().slice(-2)}>
                  {option}
                </MenuItem>
              ))}
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                label="CVV"
                variant="outlined"
                name="cc_cvc"
                onFocus={e=>{ handleCreditCardFocus('cvc') }}
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                component={renderTextField}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} order={{xs:1,lg:2}}>
          <Cards
              cvc={credicardInfo.cc_cvc || ''}
              expiry={expiry}
              focused={focus}
              name={credicardInfo.cc_name || ''}
              number={credicardInfo.cc_number || ''}
              locale={{
                valid: "validade"
              }}
              placeholders={{
                name: "NOME TITULAR"
              }}
            />
        </Grid>
      </Grid>
      <Field
        label="Selecione o número de parcelas"
        variant="outlined"
        select
        name="cc_installments"
        onFocus={e=>{ handleCreditCardFocus('name') }}
        fullWidth={true}
        margin="dense"
        size="small"
        component={renderSelectField}
        >
        {getInstallments().map((option) => (
          <MenuItem key={option[0]} value={option[0]}>
            {`${option[0]} x de ${option[1]} sem juros`}
          </MenuItem>
        ))}
        </Field>
      </React.Fragment>
    )
    
    return (
        <React.Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example" variant="fullWidth">
                    <Tab label="Cartão de Crédito" wrapped icon={<CreditCardIcon />} {...a11yProps(0)} onClick={() => {setPaymentMethod('creditcard')}} />
                    <Tab label="PIX" wrapped icon={<PixIcon />}  {...a11yProps(1)} onClick={() => {setPaymentMethod('pix')}} />
                    {/* <Tab label="Boleto Bancário" wrapped icon={<LocalAtmIcon />}  {...a11yProps(2)} onClick={() => {setPaymentMethod('billet')}} /> */}
                </Tabs>
            </Box>

            <TabPanel value={tab} index={0}>
                {creditCard()}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                {pix()}
            </TabPanel>
            <TabPanel value={tab} index={2}>
                {billet()}
            </TabPanel>
        </React.Fragment>
    )

}

export default PaymentForm