import React from 'react'
import {
  TextField,
  Grid,
  Divider,
  Link,
  MenuItem,
  LinearProgress,
  } from '@mui/material'

import countryStates from '../../utils/countryStates'

const FormMainFields = ({handleDataChange, data, CEPloading, Field, renderTextField, renderTextFieldWithMask, renderSelectField}) => {

    return (
        <React.Fragment>
            <Field
            label="Nome completo"
            variant="outlined"
            name="fullname"
            fullWidth={true}
            margin="dense"
            size="small"
            component={renderTextField}
            color="secondary"
            />
            <Field
            label="E-mail"
            variant="outlined"
            name="email"
            fullWidth={true}
            margin="dense"
            size="small"
            component={renderTextField}
            color="secondary"
            />
            <Field
            label="Confirme o E-mail"
            variant="outlined"
            name="confirmedEmail"
            fullWidth={true}
            margin="dense"
            size="small"
            component={renderTextField}
            color="secondary"
            />

            <Grid container spacing={2}>
            <Grid item xs={6}>
                <Field
                mask="999.999.999-99"
                disabled={false}
                name="document"
                component={renderTextFieldWithMask}
                >
                {(inputProps) => <TextField
                {...inputProps}
                label="CPF"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                />}
                </Field>
            </Grid>
            <Grid item xs={6}>
                <Field
                mask="(99) 99999-9999"
                disabled={false}
                name="phone"
                component={renderTextFieldWithMask}
                >
                {(inputProps) => <TextField
                {...inputProps}
                label="Celular"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                />}
                </Field>
            </Grid>
            </Grid>

            <Divider sx={{margin:'1em 0'}} />

            <Grid
            container
            spacing={2} 
            direction="row"
            justifyContent="center"
            alignItems="center"
            >
            <Grid item xs={6}>
                <Field
                mask="99999-999"
                disabled={false}
                name="addr_postalcode"
                component={renderTextFieldWithMask}
                >
                {(inputProps) => <TextField
                {...inputProps}
                label="CEP"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                />}
                </Field>

                {CEPloading && <LinearProgress />}
            </Grid>
            <Grid item xs={6}>
                <Link href="https://buscacepinter.correios.com.br/app/endereco/index.php" color="secondary" target="_blank" rel="noopener">
                Não sabe seu cep?
                </Link>
            </Grid>
            </Grid>

            <Field
                label="Endereço"
                variant="outlined"
                name="addr_line1"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                component={renderTextField}
                />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Field
                  label="Número"
                  variant="outlined"
                  name="addr_number"
                  fullWidth={true}
                  margin="dense"
                  size="small"
                  color="secondary"
                  component={renderTextField}
                  />
              </Grid>
              <Grid item xs={6}>
                  <Field
                  label="Bairro"
                  variant="outlined"
                  name="addr_district"
                  fullWidth={true}
                  margin="dense"
                  size="small"
                  color="secondary"
                  component={renderTextField}
                  />
              </Grid>
            </Grid>

            <Field
                label="Complemento"
                variant="outlined"
                name="addr_line2"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                component={renderTextField}
                />

            <Grid container spacing={2}>
            <Grid item xs={6}>
                <Field
                label="Cidade"
                variant="outlined"
                name="addr_city"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                component={renderTextField}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                label="Estado"
                variant="outlined"
                name="addr_state"
                fullWidth={true}
                margin="dense"
                size="small"
                color="secondary"
                component={renderSelectField}
                >
                {countryStates.map((option) => (
                    <MenuItem key={option} value={option}>
                    {option}
                    </MenuItem>
                ))}
                </Field>
            </Grid>
            </Grid>
        </React.Fragment>
    )

}

export default FormMainFields