import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import waterfall from 'async-waterfall'
import {
  Alert,
  CircularProgress,
  Snackbar,
  Typography,
  Box,
  Link,
  } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

import MediaControlCard from '../../components/MediaControlCard'

import { getProduct, couponValidate, savePerson, setCartInstance, placeOrder, transactions, notifyOrder } from '../../api'

import Form from './form'

const Checkout = (props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get("id")
  const link = searchParams.get("link") || 0
  const seller = searchParams.get("seller") || 0
  const sellerDiscount = searchParams.get("amount") ? Number(atob(searchParams.get("amount"))) : 0
  const freeShipping = searchParams.get("freeShipping") ? Number(searchParams.get("freeShipping")) : 0
  const date = new Date()
  const localStorageKey = 'docPayUserData' 
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false)
  const [snackbar, setSnackbar] = useState(false)
  const [snackbarHelperText, setSnackbarHelperText] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("creditcard")
  const [coupon,setCoupon] = useState("")
  const [loadingCoupon,setLoadingCoupon] = useState(false)
  const [couponData,setCouponData] = useState(null)
  const [shippingOptions,setShippingOptions] = useState([])
  const [shippingResponse,setShippingResponse] = useState(null)
  const [discount,setDiscount] = useState(0)
  // const [idOrder,setIdOrder] = useState(0)
  // const [cartSession,setCartSession] = useState(null)
  // const [order,setOrder] = useState(null)
  const [discountAmount,setDiscountAmount] = useState(0)
  const inCashDiscount = 5
  const [inCashDiscountAmount,setInCashDiscountAmount] = useState(0)
  const [subTotal,setSubTotal] = useState(0)
  const [total,setTotal] = useState(0)
  const [cart,setCart] = useState([])
  const [shipping,setShipping] = useState(0)
  const prevData = JSON.parse(localStorage.getItem(localStorageKey))
  const initialValues = prevData ? {
    fullname: searchParams.get("name") || (prevData.fullname || ''),
    email: searchParams.get("email") || (prevData.email || ''),
    confirmedEmail: searchParams.get("email") || (prevData.email || ''),
    phone: searchParams.get("phone") || (prevData.phone || ''),
    installments: searchParams.get("installments") || 6,
    addr_postalcode: searchParams.get("postalcode") || (prevData.addr_postalcode || ''),
    addr_line1: prevData.addr_line1 || '',
    addr_line2: prevData.addr_line2 || '',
    addr_number: prevData.addr_number || '',
    addr_district: prevData.addr_district || '',
    addr_city: prevData.addr_city || '',
    addr_state: prevData.addr_state || '',
  } : {
    fullname: searchParams.get("name") || '',
    email: searchParams.get("email") || '',
    confirmedEmail: searchParams.get("email") || '',
    phone: searchParams.get("phone") || '',
    addr_postalcode: searchParams.get("postalcode") || '',
    installments: searchParams.get("installments") || 6,
  }

  const [submitError, setSubmitError] = useState(false)
  const [submitErrorHelperText, setSubmitErrorHelperText] = useState("")

  const handleSubmit = (values) => {
    let invalid = 0
    setSubmitError(false)
    setSubmitErrorHelperText('')

    if (paymentMethod==="creditcard"&&!values.cc_number) { invalid++; setSubmitErrorHelperText('O número do cartão é obrigatório!') }
    else if (paymentMethod==="creditcard"&&!values.cc_name) { invalid++; setSubmitErrorHelperText('O nome do titular do cartão é obrigatório!') }
    else if (paymentMethod==="creditcard"&&!values.cc_expiryMonth) { invalid++; setSubmitErrorHelperText('O mês de expiração do cartão é obrigatório!') }
    else if (paymentMethod==="creditcard"&&!values.cc_expiryYear) { invalid++; setSubmitErrorHelperText('O ano de expiração do cartão é obrigatório!') }
    else if (paymentMethod==="creditcard"&&!values.cc_cvc) { invalid++; setSubmitErrorHelperText('O código de segurança do cartão é obrigatório!') }
    else if (paymentMethod==="creditcard"&&!values.cc_installments) { invalid++; setSubmitErrorHelperText('Selecione o número de parcelas!') }

    if (paymentMethod==="billet"&&!values.billet_installments) { invalid++; setSubmitErrorHelperText('Selecione o número de parcelas!') }

    if (freeShipping===0&&!values.shipping) { invalid++; setSubmitErrorHelperText('Selecione o método de envio!') }

    if(invalid){
      setSubmitError(true)
      return false
    }

    setPlaceOrderLoading(true)

    localStorage.setItem(localStorageKey, JSON.stringify({
      fullname: values.fullname,
      email: values.email,
      document: values.document,
      phone: values.phone,
      addr_postalcode: values.addr_postalcode,
      addr_line1: values.addr_line1,
      addr_line2: values.addr_line2,
      addr_number: values.addr_number,
      addr_district: values.addr_district,
      addr_city: values.addr_city,
      addr_state: values.addr_state,
    }))

    waterfall([
      function(callback) {

        savePerson({
          iduser: 0,
          login:values.email,
          password:'0v8#B3c&DI2$_HRb',
          isadmin:0,
          person:values.fullname,
          document:values.document,
          dateofbirth:'1969-01-01',
          email:values.email,
          phone:values.phone,
          celphone:values.phone,
          company:'',
          ienumber:'',
          authorized:1,
        })
          .then(response => {
            callback(null, response.data)
          })
          .catch(err => {
            callback(['person',err])
          })

      },
      function(person, callback) {

        let payload = {
          idproducts:id.split(","),
          iduser: person.iduser,
        }

        if(freeShipping===0){
          const shippingOpt = values.shipping.split(",")
          payload.freighttype = shippingOpt[0]
          payload.freight = shippingOpt[1]
          payload.days = shippingOpt[2]

          if (shippingResponse) {
            payload.response = JSON.stringify(shippingResponse)
          }
        }

        // if(cartSession){
        //   callback(null, person, cartSession)
        // }else{
          setCartInstance(payload)
            .then(response => {
              // setCartSession(response.data)
              // console.log(response.data)
              callback(null, person, response.data)
            })
            .catch(err => {
              callback(['cart',err])
            })
        // }

      },
      function(person, cart, callback) {

        // if(order){
        //   callback(null, person, cart, order)
        // }else{
          placeOrder({
            idcart:cart.idcart,
            iduser:person.iduser,
            person:values.fullname,
            document:values.document,
            total:total,
            discountamount:inCashDiscountAmount+sellerDiscount,
            coupon:coupon,
            discount:discount,
            seller:seller,
            paymentmethod:paymentMethod,
            billet_installments:values.billet_installments || 0,
            delivery_zipcode:values.addr_postalcode,
            delivery_address:values.addr_line1,
            delivery_number:values.addr_number,
            delivery_district:values.addr_district,
            delivery_complement:values.addr_line2 || '',
            delivery_city:values.addr_city,
            delivery_state:values.addr_state,
          })
            .then(response => {
              // setOrder(response.data)
              // console.log(response.data)
              callback(null, person, cart, response.data)
            })
            .catch(err => {
              callback(['order',err])
            })
        // }

      },
      function(person, cart, order, callback) {
        
        //If creditcard process payment

        if(paymentMethod==="creditcard"){

          transactions({
            idorder:order.idorder,
            total: total,
            cc_name: values.cc_name,
            cc_number: values.cc_number.replace(/\s/g,""),
            cc_expiryMonth: values.cc_expiryMonth,
            cc_expiryYear: values.cc_expiryYear,
            cc_cvc: values.cc_cvc,
            installments: values.cc_installments || 0,
          })
            .then(response => {
              const transaction = response.data

              callback(null,{person,cart,order,cc:transaction})
              
            })
            .catch(err => {
              console.error(err)
              callback(null,{person,cart,order})
            })

        }else{

          callback(null,{person,cart,order})

        }


      },
      function(result, callback) {

        notifyOrder(result.order.idorder)
          .then(response => {
            callback(null,result)
          })
          .catch(err => {
            callback(null,result)
          })

      },
    ], (err, result) => {

      if(err){
        setPlaceOrderLoading(false)
        setSubmitError(true)
        let errorMsg = 'Ops! Houve um erro inesperado ao realizar o seu pedido. Tente novamente mais tarde.'

        setSubmitErrorHelperText(errorMsg)
      }

      if(result){
        navigate(`/confirmacao/${result.order.idorder}`)
      }

    })


  }

  const handleCoupon = () => {
    setLoadingCoupon(true)

    couponValidate(coupon)
    .then(coupon => {
      if(coupon.status===204){
        setSubmitError(true)
        setSubmitErrorHelperText('O cupom informado é inválido ou já foi utilizado!')
      }else{
        setSnackbar(true)
        setSnackbarHelperText('Cupom aplicado com sucesso!')
        setCouponData(coupon.data.data)
        setDiscount(Number(coupon.data.data.discount))
        setDiscountAmount(((subTotal*Number(coupon.data.data.discount))/100))
      }
      setLoadingCoupon(false)
    })
    .catch(error => {
      setLoadingCoupon(false)
      setSubmitError(true)
      setSubmitErrorHelperText('Ops! Ocorreu um erro inesperado ao aplicar o cupom. Tente novamente mais tarde!')
    })

  }

  const handleCouponRemove = () => {

    setSnackbar(true)
    setSnackbarHelperText('Cupom removido!')
    setCouponData(null)
    setDiscountAmount(0)
    setDiscount(0)

  }

  useEffect(() => {
    if(link){
      navigate("/link")
    }else{

      getProduct(id)
      .then(response => {
        const products = response.data
        let newCart = cart
        let newSubTotal = 0

        products.forEach(product => {

          let price = product.price
          
          if(product.special>0){
            price = product.special
          }

          newCart.push({
            id: product.idproduct,
            name: product.product,
            width: product.width,
            height: product.height,
            length: product.length,
            weight: product.weight,
            price: Number(price),
            image:product.image,
            authors:product.custom_fields[3]["Autor(es)"],
          })

          newSubTotal = newSubTotal+Number(price)

        });

        setSubTotal(newSubTotal)
        setCart(newCart)
        setLoading(false)
        
      })
      .catch(err => {
        setLoading(false)
        setError(true)
        console.error(err)
      })
      
    }

  },[])
    

  useEffect(() => {
    setTotal(subTotal-sellerDiscount)
  },[subTotal])

  useEffect(() => {
    if(paymentMethod==='pix'){
      setInCashDiscountAmount(((subTotal*inCashDiscount)/100))
    }else{
      setInCashDiscountAmount(0)
    }
  },[paymentMethod])

  useEffect(() => {
    setTotal(subTotal-discountAmount-inCashDiscountAmount-sellerDiscount+shipping)
  },[discountAmount,inCashDiscountAmount,sellerDiscount,shipping])

  const main = (
    <React.Fragment>
      {cart.map(product => (
        <MediaControlCard
          key={`media_${product.id}`}
          image={`https://doctorlivros.com.br${product.image}`}
          title={product.name}
          subtitle={product.authors}
          bottomline={product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        />
      ))}

      <Form
        onSubmit={handleSubmit}
        cart={cart}
        loading={loading}
        initialValues={initialValues}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        setShippingOptions={setShippingOptions}
        setShippingResponse={setShippingResponse}
        shippingOptions={shippingOptions}
        inCashDiscountAmount={inCashDiscountAmount}
        discountAmount={discountAmount}
        total={total}
        placeOrderLoading={placeOrderLoading}
        sellerDiscount={sellerDiscount}
        freeShipping={freeShipping}
        shipping={{
          get:shipping,
          set:setShipping,
        }}
        coupon={{
          get:coupon,
          set:setCoupon,
          handler:handleCoupon,
          data:couponData,
          remove:handleCouponRemove,
          loading:loadingCoupon,
        }}
        submitError={{error:submitError,helperText:submitErrorHelperText,set:setSubmitError,setHelperText:setSubmitErrorHelperText}}
      />

      <Typography variant='body2'>
        Precisa de ajuda? <Link href="https://api.whatsapp.com/send?phone=5511951517333&text=Ol%C3%A1,%20tudo%20bem?%20Gostaria%20de%20tirar%20uma%20d%C3%BAvida" color="success.main" target="_blank" rel="noopener">Fale pelo WhatsApp</Link>
      </Typography>

      <Typography variant='body2' color="secondary" sx={{mt:2}}>
        Doctor Livros @ {date.getFullYear()} - Todos os direitos reservados
      </Typography>

      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
      >
        <Alert onClose={() => setSnackbar(false)} severity="success">
        {snackbarHelperText}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )

  if(!loading&&!error){
    return main
  }else{
    if(error){
      return (
        <Alert severity="warning">
        Ops! No momento não foi possível efetuar seu checkout, tente novamente mais tarde!
        </Alert>
      )
    }

    return (
      <Box sx={{display:'flex',flexDirection: 'column',alignItems:'center'}}>
        <CircularProgress />
        <Typography variant="BODY2" component="div">Carregando...</Typography>
      </Box>
    )
  }

}

export default Checkout