import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player';
import {
    Grid,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Box,
    Button,
    Divider,
    TextField
  } from '@mui/material'

import { getOrder, getPix } from '../../api';
import CopyToClipboard from 'react-copy-to-clipboard'

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PercentIcon from '@mui/icons-material/Percent';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CopyAllIcon from '@mui/icons-material/CopyAll';

const Order = () => {

    let params = useParams();
    const navigate = useNavigate();

    const id = params.id
    const [data, setData] = useState({
        idstatus: null
    })
    const [loading, setLoading] = useState(true)
    const [copied, setCopied] = useState(false)
    const [pixData,setPixData] = useState({
      status:null
    })

    const copyPixCode = () => {
      setCopied(true)

      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }

    const pix = () => (
        <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{minHeight:"100%"}}>
            <CardContent>
              <PhoneIphoneIcon />
              <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
              Acesse seu banco
              </Typography>
              <Typography variant="body2" color="secondary">
              Abra o app do seu banco ou instituição financeira de sua preferência e entre no ambiente PIX
              </Typography>
            </CardContent>
          </Card>
          </Grid>
          <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{minHeight:"100%"}}>
            <CardContent>
              <QrCodeScannerIcon />
              <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
              Ler QR Code
              </Typography>
              <Typography variant="body2" color="secondary">
              Em seguida aponte a câmera para o QR Code PIX que está sendo exibido abaixo.
              </Typography>
            </CardContent>
          </Card>
          </Grid>
          <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{minHeight:"100%"}}>
            <CardContent>
              <CopyAllIcon />
              <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
              PIX Copia e Cola
              </Typography>
              <Typography variant="body2" color="secondary">
              Se preferir pague com o PIX copia e cola utilizando o código apresentado na sequência do QR Code.
              </Typography>
            </CardContent>
          </Card>
          </Grid>
        </Grid>
        
        <Card variant="outlined" sx={{mt:1}}>
          <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} lg={6}>
                <CardContent sx={{textAlign:'center'}}>
                  <img src={`data:image/gif;base64,${pixData.qrcode.imagem_base64}`} alt="QR Code" />
                </CardContent>
              </Grid>
              <Grid item xs={12} lg={6} sx={{p:2}}>
                <Divider sx={{mb:1}}>
                  OU
                </Divider>
                <TextField
                  color="secondary"
                  multiline
                  rows={4}
                  defaultValue={pixData.qrcode.emv}
                  fullWidth
                  sx={{mb:1}}
                  disabled
                />
                <CopyToClipboard
                  text={pixData.qrcode.emv}
                  onCopy={copyPixCode}>
                  <Button variant='contained' size='small' color='secondary' fullWidth>{copied ? 'Copiado com sucesso!' : 'Copiar código'}</Button>
                </CopyToClipboard>
              </Grid>
          </Grid>
        </Card>
        </div>
      )

    const billet = () => (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
          <Card variant="outlined">
            <CardContent>
              <PercentIcon />
              <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
              Parcelado no boleto
              </Typography>
              <Typography variant="body2" color="secondary">
              Você está pagando em boleto para 30, 60 e 90 dias da data da compra.
              </Typography>
            </CardContent>
          </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
          <Card variant="outlined">
            <CardContent>
              <AttachEmailIcon />
              <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
              Receba por e-mail
              </Typography>
              <Typography variant="body2" color="secondary">
              Você receberá as cobranças por e-mail com os boletos para pagamento.
              </Typography>
            </CardContent>
          </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
          <Card variant="outlined">
            <CardContent>
              <ThumbUpIcon />
              <Typography variant="h6" component="div" color="secondary" sx={{lineHeight:'1.2em',pb:1}}>
              Aproveite!
              </Typography>
              <Typography variant="body2" color="secondary">
              Você irá aproveitar sua compra antes mesmo de começar a pagar por ela.
              </Typography>
            </CardContent>
          </Card>
          </Grid>
        </Grid>
      )

    useEffect(() => {
        async function fetchData() {

          await getOrder(id)
          .then(async response => {
            const order = response.data
            setData(order)
  
            if(order.paymentmethod==="pix"){
             
              await getPix({idorder:id})
                  .then(response => {
                      setPixData(response.data)
                  })
                  .catch(err => {
                      console.error(err)
                  })

            }
            
            setLoading(false)
          })
          .catch(err => {
            setLoading(false)
            console.error(err)
          })

        }

        fetchData()
    },[id])

    if(!loading){
        if(Number(data.idstatus) !== 6){
            return (
                <div>
                    <Player
                    autoplay
                    loop
                    src="https://assets5.lottiefiles.com/private_files/lf30_yo2zavgg.json"
                    style={{ height: '200px', width: '200px' }}
                    >
                    </Player>
                    <Typography variant='h4' component='div' sx={{textAlign:'center',color:'success.main',mb:2}}>
                        {data.paymentmethod==="pix" && <div>Pague agora com <b>PIX</b> o pedido <b>{id}</b>!</div>}
                        {data.paymentmethod!=="pix" && <div>Pedido <b>{id}</b> realizado com sucesso!</div>}
                    </Typography>
                    <Typography variant='body2' component='div' sx={{textAlign:'center',mb:2}}>
                        Você receberá por e-mail o andamento do seu pedido. Agradecemos a preferência.
                    </Typography>

                    {data.paymentmethod==="pix" && pix()}
                    {data.paymentmethod==="billet" && billet()}
                </div>
            )
        }

        if(Number(data.idstatus) === 6){
            return (
                <div>
                    <Player
                    autoplay
                    loop
                    src="https://assets4.lottiefiles.com/private_files/lf30_ns1jrplu.json"
                    style={{ height: '200px', width: '200px' }}
                    >
                    </Player>
                    <Typography variant='h3' component='div' sx={{textAlign:'center',color:'error.main'}}>
                       <b>Ops!</b>
                    </Typography>
                    <Typography variant='h5' component='div' sx={{textAlign:'center',color:'primary.main',mb:2}}>
                        Ocorreu um problema com seu pedido!
                    </Typography>
                    <Typography variant='body2' component='div' sx={{textAlign:'center'}}>
                        Tente novamente clicando no botão abaixo. Lembre-se de conferir atentamente os dados do seu cartão de crédito bem como conferir o seu saldo disponível para realizar a transação.
                    </Typography>
                    <Button variant='contained' size='large' color="primary" fullWidth sx={{mb:1,mt:3}} onClick={() => { navigate(-1) }}>Tentar novamente</Button>
                </div>
            )
        }
      }else{
        return (
          <Box sx={{display:'flex',flexDirection: 'column',alignItems:'center'}}>
            <CircularProgress color='success' />
          </Box>
        )
      }

}

export default Order