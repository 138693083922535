import {
    THEME_MODE_CHANGED
} from '../actions/actionsTypes'

const theme = localStorage.getItem("docl_theme")

const INITIAL_STATE = {
    mode: theme || "light"
}

export default function headerReducer(state = INITIAL_STATE, action) {

    switch(action.type) {
        case THEME_MODE_CHANGED:
            localStorage.setItem("theme",action.payload)
            return {
                ...state,
                mode: action.payload
            }
        default:
            return state
    }

}